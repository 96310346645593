import { Box, Text } from "@chakra-ui/layout";

interface DeviceReadingItemProps {
  icon: JSX.Element;
  value: string;
  showBorderTop?: boolean;
  isHighRisk?: boolean;
  mr?: number;
}

export const DeviceReadingItem = ({ icon, value, isHighRisk, mr = 4 }: DeviceReadingItemProps) => (
  <Box display="flex" flexDirection="row" alignItems="center" mr={mr}>
    <Box
      borderRadius="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg={isHighRisk ? "red.50" : "gray.50"}
      w="28px"
      h="28px"
      mr={2}
    >
      {icon}
    </Box>
    <Text color={isHighRisk ? "red.600" : "gray.500"}>{value}</Text>
  </Box>
);
