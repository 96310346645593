import { getOrdinalNumber } from "./ordinalNumber";

export const makeFloorName = (levelIndex?: number): string => {
  if (levelIndex === undefined || levelIndex === null) {
    return "Unknown Storey";
  }

  if (levelIndex === 0) {
    return "Ground";
  }

  if (levelIndex > 0) {
    return `${getOrdinalNumber(levelIndex)} Storey`;
  }

  // negative level
  return `B${levelIndex * -1}`;
};
