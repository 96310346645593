import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Icon, Link, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";
import { RoomWithLocationReadings } from "../../../lib/api/types/Room";
import { UserPinnedEntityType } from "../../../lib/api/types/UserPinnedEntity";
import { isSampleReallyOld } from "../../../lib/utils/isSampleRecent";
import { PinButton } from "../../button/PinButton";
import { RoomIcon } from "../../create-room/room-icon/RoomIcon";
import { ComfortScoreTag } from "../../property/ComfortScoreTag";
import { ManageRoomMenu } from "../../property/ManageRoomMenu";
import { RoomLocationReading } from "../room-location-reading/RoomLocationReading";

export const AREA_CARD_VERTICAL_PADDING = 4;
export const AREA_CARD_HORIZONTAL_PADDING = 4;

interface InsideDetailsProps {
  room: RoomWithLocationReadings;
}

export const InsideDetails = ({ room }: InsideDetailsProps) => {
  const router = useRouter();

  const roomLocationsWithDevice = room.roomLocations.filter((l) => l.device);
  const roomLocationsWithNoDevices = room.roomLocations.filter((l) => !l.device);

  return (
    <Box
      borderWidth={1}
      borderColor="gray.50"
      key={room.id}
      py={AREA_CARD_VERTICAL_PADDING}
      shadow="lg"
      mb="8px"
      borderRadius={6}
      bg="white"

      // _hover={
      //   roomLocationsWithDevice.length > 0
      //     ? {
      //         ":not(:has(.chakra-button:hover))": {
      //           bg: "gray.50",
      //           border: "1px solid",
      //           borderColor: "gray.400",
      //           cursor: "pointer",
      //           ".room-location-row": {
      //             bg: "#f6f6f6",
      //           },
      //         },
      //       }
      //     : {}
      // }
    >
      <Flex px={AREA_CARD_HORIZONTAL_PADDING} dir="row" alignItems="center" justifyContent="space-between">
        <Flex>
          <RoomIcon type={room.type} />
          <Link
            ml={2}
            mb={roomLocationsWithDevice.length > 0 ? 2 : 1}
            fontWeight={700}
            href={`/area/room/${room.id}`}
          >
            {room.name}
          </Link>
        </Flex>
        <Flex alignItems="center">
          {roomLocationsWithDevice.length > 0 &&
            roomLocationsWithDevice[0].insights?.comfortScore !== undefined &&
            !isSampleReallyOld(roomLocationsWithDevice[0].device?.lastSample) && (
              <ComfortScoreTag comfortScore={roomLocationsWithDevice[0].insights?.comfortScore} />
            )}

          {roomLocationsWithDevice.length > 0 && (
            <PinButton
              entityId={room.id}
              entityType={UserPinnedEntityType.Room}
              propertyId={room.propertyId}
              size={21}
            />
          )}
          {roomLocationsWithDevice.length > 0 && (
            <Button variant="outline" size="sm" onClick={() => router.push(`/area/room/${room.id}`)} mx={2}>
              View Readings
              <Icon boxSize={6} as={ChevronRightIcon} />
            </Button>
          )}
          <ManageRoomMenu
            roomId={room.id}
            propertyId={room.propertyId}
            roomName={room.name}
            hasDevice={roomLocationsWithDevice.length > 0}
          />
        </Flex>
      </Flex>

      {roomLocationsWithDevice.map((l) => (
        <Box key={`room-location-row-${l.id}`} className="room-location-row chakra-button">
          <RoomLocationReading
            showCovidRiskLevel={true}
            roomLocation={l}
            device={l.device}
            locationType="ROOMLOCATION"
            propertyId={room.propertyId}
            showRoomName={roomLocationsWithDevice.length > 1 || room.name !== l.name}
          />
        </Box>
      ))}
      {roomLocationsWithNoDevices.map((l) => (
        <Box key={`room-location-row-${l.id}`} className="room-location-row chakra-button">
          <RoomLocationReading
            showCovidRiskLevel={true}
            roomLocation={l}
            device={l.device}
            locationType="ROOMLOCATION"
            propertyId={room.propertyId}
            showRoomName={roomLocationsWithDevice.length > 1 || room.name !== l.name}
          />
        </Box>
      ))}
    </Box>
  );
};
