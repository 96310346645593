import { Box } from "@chakra-ui/react";
import React from "react";
import { RoomWithLocationReadings } from "../../../lib/api/types/Room";
import { RoomLocationWithReading } from "../../../lib/api/types/RoomLocation";
import { RoomLocationReading } from "../room-location-reading/RoomLocationReading";
import { getSortedRoomLocations } from "../room-sort/getSortedRoomLocations";
import { RoomsSort } from "../room-sort/types";
import { AREA_CARD_HORIZONTAL_PADDING, AREA_CARD_VERTICAL_PADDING } from "./InsideDetailsPanel";

interface SortedRoomLocationsProps {
  rooms: RoomWithLocationReadings[];
  searchText: string;
  sort: RoomsSort;
  propertyId: string;
}

export const SortedRoomLocations = ({ rooms, searchText, sort, propertyId }: SortedRoomLocationsProps) => {
  const roomLocations: RoomLocationWithReading[] = rooms
    .reduce((all: RoomLocationWithReading[], next) => {
      return [...all, ...next.roomLocations];
    }, [])
    .filter((r) => r.name?.toLowerCase().includes(searchText.toLowerCase()));

  const sortedRoomLocations = getSortedRoomLocations(roomLocations, sort);

  return (
    <>
      {sortedRoomLocations.map((l) => (
        <Box
          borderWidth={1}
          borderColor="gray.50"
          key={l.id}
          py={AREA_CARD_VERTICAL_PADDING}
          px={AREA_CARD_HORIZONTAL_PADDING}
          shadow="lg"
          mb="8px"
          borderRadius={6}
          bg="white"
        >
          <RoomLocationReading
            locationType="ROOMLOCATION"
            showCovidRiskLevel={true}
            roomLocation={l}
            device={l.device}
            propertyId={propertyId}
          />
        </Box>
      ))}
    </>
  );
};
