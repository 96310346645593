import { Alert, Box, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";
import { RoomWithLocationReadings } from "../../../lib/api/types/Room";
import { makeRoomsByLevel } from "../../property/room/makeRoomsByLevel";
import { RoomsSort } from "../room-sort/types";
import { InsideDetails } from "./InsideDetails";
import { SortedRoomLocations } from "./SortedRoomLocations";

export const AREA_CARD_VERTICAL_PADDING = 4;
export const AREA_CARD_HORIZONTAL_PADDING = 4;

interface InsideDetailsPanelProps {
  rooms: RoomWithLocationReadings[];
  propertyId: string;
  searchText: string;
  sort?: RoomsSort;
  hideLevels?: boolean;
}

export const InsideDetailsPanel = ({
  rooms,
  propertyId,
  searchText,
  sort,
  hideLevels,
}: InsideDetailsPanelProps) => {
  const router = useRouter();

  if (sort) {
    return (
      <Box mt="16px">
        <SortedRoomLocations propertyId={propertyId} rooms={rooms} sort={sort} searchText={searchText} />
      </Box>
    );
  }
  const byLevel = makeRoomsByLevel<RoomWithLocationReadings>(rooms);

  if (!byLevel.length) {
    return (
      <Alert my={5} colorScheme={"blue"}>
        You do not have inside locations on this property
      </Alert>
    );
  }

  return (
    <Box mt="16px">
      {byLevel.map((level) => (
        <Box key={level.floorIndex} mb="32px">
          <Text mb="8px" fontSize={18} fontWeight="bold">
            {hideLevels ? "" : level.levelLabel}
          </Text>
          {level.rooms
            .filter((r) => {
              if (searchText) {
                return r.name.toLowerCase().includes(searchText.toLowerCase());
              }
              return r;
            })
            .map((r) => (
              <InsideDetails key={r.id} room={r} />
            ))}
        </Box>
      ))}
    </Box>
  );
};
