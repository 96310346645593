export const getOrdinalNumber = (number: number): string => {
  if ([1, 2, 3].includes(number % 10) && Math.floor(number / 10) % 10 !== 1) {
    switch (number % 10) {
      case 1:
        return `${number}st`;
      case 2:
        return `${number}nd`;
      case 3:
        return `${number}rd`;
    }
  }
  return `${number}th`;
};
